export interface Preview {
  storyPreviewId?: number
  link?: string
  fileType: 'Image' | 'Video'
}

export interface Story {
  title?: string
  subTitle?: string
  description?: string
  previews?: Preview[]
  ranking?: number
  storyId?: number
  mainImageLink?: string
  isAdult?: boolean
  bannerType?: 'Story' | 'Link'
  buttonName?: string
  url?: string
  showReleaseDate?: boolean
  chapterProgress?: number
  bundleId?: number
}

export interface RankingSection {
  title?: string
  hasViewMore: boolean
  storyList?: Story[]
}

export interface Section {
  title?: string
  recommendedTargetText?: string
  showProgress?: boolean
  hasViewMore?: boolean
  uiType:
    | 'slider'
    | 'carousel-S'
    | 'carousel-M'
    | 'carousel-L'
    | 'rankingList'
    | 'rankingCarousel'
    | 'genreList'
    | string
  storyList?: Story[]
  sectionList?: RankingSection[]
  sectionId: number
  isTestSection: boolean
  sectionType?: StorygameSectionType | null
}

export const CAROUSEL_SIZES = {
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
} as const

export type CarouselSizes = keyof typeof CAROUSEL_SIZES

export const STORY_PLAY_TAB_TYPES = {
  storyGame: 'storyGame',
  challenge: 'challenge',
  interactive: 'interactive',
  webNovel: 'webNovel',
} as const

export type StoryPlayTabType = keyof typeof STORY_PLAY_TAB_TYPES

/** 스토리게임 섹션 UI 타입 */

export const STORY_GAME_SECTION_UI_TYPE = {
  CarouselLarge: 'CarouselLarge',
  CarouselMedium: 'CarouselMedium',
  CarouselProgress: 'CarouselProgress',
  CarouselRank: 'CarouselRank',
  CarouselSmall: 'CarouselSmall',
  RankList: 'RankList',
  Slider: 'Slider',
} as const

export type StorygameSectionUiType = keyof typeof STORY_GAME_SECTION_UI_TYPE

export interface StoryType {
  genre?: string
  name?: string
  shortDesc?: string
  storyId?: number
  storygameCoverImageFile?: {
    link?: string
  }
  storygamePreviews?: {
    file?: {
      link?: string
    }
    fileType?: 'Image' | 'Video'
    storyPreviewId?: number
  }[]
  storygameReleaseDate?: Date | null
  bundleId?: number
  isAdult?: boolean
  myFavorite?: boolean
  storyTags?: { name?: string; storyTagId?: number }[]
  ranking: number
  chapterProgress?: number
  // 리스트 순위형의 랭크
  rank?: number
}

export interface SectionRankListType {
  sectionTitle?: string
  stories?: StoryType[]
  viewMore?: boolean
}

export interface SectionSliderType {
  story?: StoryType
  linkData?: {
    buttonName?: string
    content?: string
    imageFile?: {
      link?: string
    }
    imageFileId?: number
    subTitle?: string
    title?: string
    url?: string
  }
  storyId?: number
  type?: 'Story' | 'Link'
}

export type GetStorygameSectionViewMoreQuery = {
  __typename?: 'Query'
  getStorygameSectionViewMore?: {
    __typename?: 'GetStorygameSectionViewMoreOutput'
    totalCount: number
    sectionView?: {
      __typename?: 'StorygameSectionView'
      sectionTitle?: string | null
      sectionId: number
      uiType: StorygameSectionUiType
      sectionData: string
      isTestSection: boolean
      hasViewMore: boolean
      showReleaseDate: boolean
      sectionType?: StorygameSectionType | null
    } | null
  } | null
}

export interface IStorygameSectionViewMore {
  title: string
  hasRanking: boolean
  totalCount: number
  recommendedTargetText?: string
  storyList?: {
    storyId: number
    coverImageUrl: string
    title: string
    subTitle: string
    isAdult: boolean
    ranking: number
    tag: { name?: string; storyTagId?: number }[]
    myFavorite: boolean
    releaseDate: Date
    showReleaseDate?: boolean
    bundleId?: number
  }[]
}

export interface IStorygameSectionViewMoreStoryList {
  storyId: number
  coverImageUrl: string
  title: string
  subTitle: string
  isAdult: boolean
  ranking: number
  tag: { name?: string; storyTagId?: number }[]
  myFavorite: boolean
  releaseDate: Date
  showReleaseDate: boolean
}

// sectionType: GameBasedRecommendation
export type StorygameSectionDataGameBasedRecommendation = {
  targetStoryName: string
  stories: StoryType[]
}

// sectionType: TagBasedRecommendation
export type StorygameSectionDataTagBasedRecommendation = {
  targetTagName: string
  stories: StoryType[]
  totalCount: number
}

export const STORYGAME_SECTION_TYPE = {
  Custom: 'Custom',
  GameBasedRecommendation: 'GameBasedRecommendation',
  SliderBanner: 'SliderBanner',
  TagBasedRecommendation: 'TagBasedRecommendation',
} as const

export type StorygameSectionType = keyof typeof STORYGAME_SECTION_TYPE
